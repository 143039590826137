@use '../base/vars' as *;

.greeting_container{
    max-width: 1180px;
    margin: 0 auto;
    padding: 100px 20px 140px 20px;
    font-size: 1.0625rem;

    @include media_query(){
        padding: 60px 0 90px;
    }
}
.greeting_01{
    display: flex;

    @include media_query(){
        display: block;
        position: relative;
        padding: 0 25px;
    }

    .about_section_title{
        @include rem(30);
        font-weight: bold;
        margin-bottom: 20px;

        @include media_query(){
            @include rem(24);
            line-height: 1.46;
        }
    }

    .greeting_body01{
        flex: 1;
        @include rem(17);
        line-height: 2;

        p + p{
            margin-top: 20px;
        }

        @include media_query(){
            @include rem(16);
        }
    }

    .greeting_image{
        width: 388px;
        margin-left: 80px;
        margin-right: 80px;
        margin-top: 60px;

        @include media_query(){
            width: 335px;
            position: absolute;
            top: 200px;
            left: 0;
            margin: 0;
            margin-top: 0;
        }

        img{
            width: 100%;
            height: auto;

            box-shadow: 100px 50px #5E8FC1;

            @include media_query(){
                box-shadow: 100px -60px #5E8FC1;
            }
        }
    }
}

.about_title{
    @include rem(42);
    letter-spacing: 6.3px;
    line-height: 1.7;

    @include media_query(){
        margin-bottom: 600px;
    }

    span{
        display: block;
        @include rem(18);
        font-weight: normal;
        letter-spacing: 1px;
        margin-bottom: 40px;
    }
}

.greeting_02{

    @include media_query(){
        padding: 0 25px;
    }

    .greeting_body02{
        @include rem(17);
        margin-top: 40px;
        line-height: 2;

        p + p{
            margin-top: 20px;
        }
    }

    .doctor_name{
        margin-top: 20px;
        margin-bottom: 80px;
        display: flex;
        letter-spacing: 1.76px;
        justify-content: flex-end;
        align-items: center;

        .doctor_name_image{
            margin-left: 20px;
        }
    }

}

.greeting_03{
    display: flex;
    border-top: solid 1px #5e8fc1;

    @include media_query(){
        display: block;
        // padding: 0 25px;
        margin-left: 25px;
        margin-right: 25px;

    }

    .greeting_list_area{
        margin-top: 60px;
        width: calc(50% - 20px);

        @include media_query(){
            width: 100%;
        }

        .greeting_list_title{
            @include rem(24);
            letter-spacing: 3.6px;
            line-height: 1.5;

            span{
                display: block;
                @include rem(15);
                font-weight: normal;
                letter-spacing: 1px;
                margin-bottom: 20px;

                @include media_query(){
                    @include rem(14);
                    line-height: 2.71;
                }
              }

            @include media_query(){
                @include rem(20);
            }

        }

        .greeting_list_body{

            .greeting_list{
                list-style: none;

                li{
                    display: flex;
                    line-height: 2;
                    align-items: center;

                    &::before{
                        display: block;
                        margin-right: 12px;
                        width: 15px;
                        height: 2px;
                        background-color: #2e639b;
                        content: "";
                    }

                    @include media_query(){
                        @include rem(16);
                    }
                }
            }
        }
    }
}


.clinic_info{
    margin-top: 60px;
    background-image: linear-gradient(to bottom, #2e639b 0%, #5e8fc1 400px, transparent 400px, transparent 100%);
    padding-left: 20px;
    padding-right: 20px;

    @include media_query(){
        margin-top: 0px;
    }

    .clinic_info_title{
        @include rem(42);
        padding-top: 120px;
        letter-spacing: 6.3px;
        line-height: 1.7;
        color: #fff;
        max-width: 1140px;
        margin: 0 auto;

        span{
            display: block;
            @include rem(18);
            font-weight: normal;
            letter-spacing: 1px;
            margin-bottom: 100px;

            @include media_query(){
              @include rem(14);
              margin-bottom: 60px;
            }
        }

        @include media_query(){
          padding-top: 60px;
          @include rem(32);
        }
    }
}

.clinic_info_body{
    max-width: 945px;
    margin: auto;
    margin-bottom: 200px;
    background-color: #fff;
    border-radius: 5px;
    padding: 60px 95px 80px 95px;
    box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);

    @include media_query(){
        max-width: 325px;
        padding: 40px 15px 80px 15px;
        margin-bottom: 100px;
    }

    .clinic_info_body_title{
        @include rem(24);
        font-weight: 500;
    }

}

.clinic_info_table{
    width: 100%;
    margin-top: 30px;

    th,td{
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
        @include rem(17);
        text-align: left;
        padding: 20px 0;

        @include media_query(){
          @include rem(16);
        }
    }

    th{
        font-weight: 500;
        width: 263px;
        border-top-color: #2e639b;
        border-bottom-color: #2e639b;

        @include media_query(){
          width: 75px;
        }
    }

    td{
        line-height: 2;

        .clinic_info_list{
            list-style: none;

            @include media_query(){
                @include rem(15);
            }


            li{
                display: flex;
                line-height: 2;
                align-items: center;

                &::before{
                    display: block;
                    margin-right: 12px;
                    width: 15px;
                    height: 2px;
                    background-color: #2e639b;
                    content: "";
                }
            }
        }
    }
}


.clinic_photo_container{
    max-width: 1180px;
    margin: 0 auto;
    padding: 100px 0 140px 0;
    font-size: 1.0625rem;

    @include media_query(){
        padding: 60px 0 90px;
    }
}

.clinic_photo{


    .clinic_photo_body{
        display: flex;
        padding: 0 20px;


        @include media_query(){
            display: block;
            position: relative;
            padding: 0 25px;
        }

        .clinic_photo_body_text{
            flex: 1;
            @include rem(17);
            margin-top: 40px;
            line-height: 2;

            @include media_query(){
                @include rem(16);
                margin-top: 0px;
            }

            .clinic_photo_bodytitle{
                @include rem(42);
                letter-spacing: 6.3px;
                line-height: 1.7;

                @include media_query(){
                    margin-bottom: 600px;
                    @include rem(32);
                }

                span{
                    display: block;
                    @include rem(18);
                    font-weight: normal;
                    letter-spacing: 1px;
                    margin-bottom: 40px;

                    @include media_query(){
                        @include rem(14);
                    }
                }
            }
        }

        .clinic_photo_title_image{
            width: 388px;
            margin-left: 80px;
            margin-right: 80px;
            margin-top: 120px;

            @include media_query(){
                width: 335px;
                position: absolute;
                top: 200px;
                left: 0;
                margin: 0;
                margin-top: 0;
            }

            img{
                width: 100%;
                height: auto;

                box-shadow: 100px 50px #5E8FC1;
                // box-shadow: 100px 50px #2E639B;

                @include media_query(){
                    box-shadow: 100px -60px #5E8FC1;
                    // box-shadow: 100px -60px #2E639B;
                }
            }
        }
    }
}
