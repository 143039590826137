@use '../base/vars' as *;

@keyframes Translate {
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, 5px); }
    100% { transform: translate(0, 0); }
}

.top-swiper{
    height: 800px;
    overflow: hidden;

    @include media_query(){
        height: 100vh;
    }

    img{
        width: 100%;
        height: 100vh;
        object-fit: cover;

        @include media_query(){
            width: 100vw;
            height: 100vh;
            object-fit: cover;
        }
    }
}

.main_header_top{
    position: relative;
    height: 800px;
    // background-image: url(../img/top/top_03_slider.jpg);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center top;
    text-align: center;

    // @include media_query(){
    //     padding: 0 25px;
    //     height: 100vh;
    //     background-image: url(../img/top/top_03_slider_sp.jpg);
    // }

    @include media_query(){
        height: 100vh;
    }
}

.catchword_wrap{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catchword{
    padding: 30px;
    border: solid 20px #fff;

    @include media_query(){
        padding: 15px;
        border: solid 10px #fff;
    }

    .catchword_inner{
        background-color: rgba(#2e639b, .85);
        color: #fff;
        padding: 90px 25px;

        @include media_query(){
            padding: 50px 15px;
        }

        .catchword_title{
            @include rem(42);
            font-weight: 800;
            line-height: 1.4;
            letter-spacing: 3px;

            @include media_query(){
                @include rem(22);
            }
        }

        .catchword_small{
            @include rem(17);
            margin-top: 16px;

            @include media_query(){
                @include rem(14);
                margin-top: 5px;
            }
        }
    }
}


.scroll_anchor{
    position: absolute;
    left: 0;
    bottom: 40px;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include rem(16);
    font-weight: 500;
    cursor: pointer;

    a{
        text-decoration: none;
        color: #fff;

        &:hover{
            opacity: 1;
        }
    }

    &::after{
        font-family: "Font Awesome 5 Free";
        content: "\f107";
        color: #fff;
        line-height: 1;
        @include rem(20);

        animation: .8s Translate ease-in-out infinite;
    }
}





.section_about{
    padding: 0 25px;
    @include media_query(){
        padding: 0;
    }


    .about_title{
        @include media_query(){
            margin-bottom: 580px !important;
        }
    }

    .section_img img {
        @include media_query(){
            // box-shadow: 60px -50px #2E639B;
            box-shadow: 60px -50px #5E8FC1;
        }
    }

    .general_treatment{
        padding-bottom: 200px;

        @include media_query(){
            padding-bottom: 80px;
        }

        .section_title{
            margin-bottom: 40px;
        }
    }

    .kysmile{
        max-width: 570px;
        width: 100%;
        height: auto;

        @include media_query(){
            margin-top: 20px;
        }
    }
}




.section_treatment{
    padding: 120px 0 180px 0;
    background-image: linear-gradient(to bottom, #2e639b 0%, #5e8fc1 400px, transparent 400px, transparent 100%);

    @include media_query(){
        padding: 60px 0 100px;
        background-image: linear-gradient(to bottom, #2e639b 0%, #5e8fc1 765px, transparent 765px, transparent 100%);
    }

    .section_title{
        @include rem(42);
        line-height: 1.5;
        color: #fff;
        margin-bottom: 100px;
        letter-spacing: 6.3px;
        text-shadow: 0 3px 6px rgba(46, 99, 155, 0.3);

        @include media_query(){
            @include rem(32);
            margin-bottom: 60px;
        }

        span{
            display: block;
            @include rem(18);
            font-weight: normal;
            letter-spacing: normal;
        }
    }

    .section_treatment_wrap{
        max-width: 1190px;
        margin: 0 auto;
        padding: 0 25px;
    }

    .section_treatment_inner{
        max-width: 945px;
        margin: 0 auto;
    }

    .treatment_text{
        margin-top: 60px;
        @include rem(17);
        line-height: 2;
        letter-spacing: -.025em;

        @include media_query(){
            @include rem(16);
        }

        p + p{
            margin-top: 2em;
        }
    }
}




.break_img{
    width: 100%;
    height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media_query(){
        height: 375px;
    }

    p{
        @include rem(42);
        font-weight: 800;
        letter-spacing: 6.3px;
        color: #fff;
        text-shadow: 0 3px 6px rgba(46, 99, 155, 0.3);

        @include media_query(){
            @include rem(24);
            letter-spacing: 3.6px;
        }
    }

    &.break_before_information{
        background-image: url(../img/general-parts/obi-img02.jpg);

        @include media_query(){
            background-image: url(../img/general-parts/obi-img02_sp.jpg);
        }
    }

    &.break_before_access{
        background-image: url(../img/general-parts/obi-img03.jpg);

        @include media_query(){
            background-image: url(../img/general-parts/obi-img03_sp.jpg);
        }
    }
}

.section_flex{
    display: flex;
}

.section_flex_img{
    @include media_query(){
        display: none;
    }
}

.section_information{
    max-width: 1190px;
    margin: 0 auto;
    padding: 120px 25px 200px;

    @include media_query(){
        padding: 60px 25px 100px;
    }

    .section_flex_info{
        width: 100%;
        max-width: 570px;
        margin-right: 100px;

        @include media_query(){
            max-width: 100%;
            margin-right: 0;
        }
    }

    .treatment_pay{
        margin-top: 20px;
        @include media_query(){
            margin-top: 30px;
            margin-bottom: 60px;
        }

        .btn_treatment{
            margin-bottom: 30px;

            @include media_query(){
                margin-bottom: 0;
            }
        }
    }
}



.section_access{
    .section_access_inner{
        max-width: 1190px;
        margin: 0 auto;
        padding: 120px 25px;

        @include media_query(){
            padding: 40px 0 100px 0;
        }

        .heading_top-text{
            //padding: 0 25px;
        }
    }
}

.access_info{
    display: flex;
    max-width: 946px;
    margin: 0 auto;
    margin-top: 100px;
    box-shadow: 0 3px 6px 0 rgba(46, 99, 155, 0.3);
    background-image: linear-gradient(176deg, #2e639b 56%, #5e8fc1 96%);
    color: #fff;

    @include media_query(){
        flex-direction: column;
        margin-top: 60px;
        box-shadow: none;
    }

    .access_info_map{
        width: 50%;

        @include media_query(){
            width: 100%;
            height: 375px;
        }

        iframe{
            width: 100%;
            height: 100%;
        }
    }

    .access_info_text{
        width: 50%;

        @include media_query(){
            width: 100%;
        }
    }

    .access_info_text_inner{
        padding: 65px 60px 65px 60px;

        @include media_query(){
            padding: 40px 25px 100px;
        }

        p + p{
            margin-top: 10px;
        }

        .address{
            @include rem(17);

            @include media_query(){
                @include rem(16);
            }

            span{
                @include rem(15);

                @include media_query(){
                    @include rem(14);
                }
            }
        }

        .train,
        .car{
            @include rem(15);

            @include media_query(){
                @include rem(14);
            }
        }

        .tel{
            @include rem(36);
            margin: 30px 0;
            line-height: 1;
            font-weight: bold;
            
            a{
                color: #fff;
                text-decoration: none;
            }

            @include media_query(){
                @include rem(32);
                text-align: center;
            }

            i{
                @include rem(27);
                vertical-align: middle;
                margin-top: -5px;

                @include media_query(){
                    @include rem(22);
                }
            }
        }

        .schedule{
            img{
                width: 100%;
                height: auto;
            }

            p{
                margin-top: 20px;
                @include rem(15);

                @include media_query(){
                    @include rem(14);
                }
            }
        }

        .map_btn{
            margin-top: 40px;

            a{
                display: block;
                width: 160px;
                height: 54px;
                color: #2e639b;
                box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
                background-color: #ffffff;
                border-radius: 30px;
                line-height: 54px;
                text-align: center;
                text-decoration: none;

                @include media_query(){
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                    @include rem(15);
                }

                &::after{
                    font-family: "Font Awesome 5 Free";
                    content: "\f105";
                    margin-left: 10px;
                }
            }
        }
    }


}
