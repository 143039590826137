@use '../base/vars' as *;

.price_box{
    max-width: 945px;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media_query(){
        padding: 0px 15px 0px 15px;
    }

    .price_box_head{

        .price_title{
            text-align: center;
            @include rem(24);
            margin-bottom: 40px;
            margin-top: 60px;
            @include media_query(){
                @include rem(20);
                margin-top: 40px;
            }
        }

        .price_title_body{
            text-align: center;
            margin-bottom: 45px;
        }
    }

    .price_box_image{
        margin-bottom: 60px;
    }
}

.price_pay{
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 100px;
    @include media_query(){
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .btn_price{
        a{
            display: block;
            width: 160px;
            height: 54px;
            color: #fff;
            box-shadow: 0 1px 15px 0 rgba(46, 99, 155, 0.3);
            background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
            border-radius: 30px;
            line-height: 54px;
            text-align: center;
            text-decoration: none;
            &::after{
                font-family: "Font Awesome 5 Free";
                content: "\f105";
                margin-left: 10px;
            }
        }
    }
}

.price_link_area{

    .price_link_list{
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        @include media_query(){
            display: block;
        }

        li{
            display: flex;
            align-items: center;
            margin-left: 20px;
            width: calc(33.3% - 20px);
            list-style: none;
            &::before{
                display: block;
                width: 15px;
                height: 2px;
                background-color: #2e639b;
                content: "";
            }
            @include media_query(){
                width: auto;
                margin-bottom: 30px;
            }

            a{
                margin-left: 12px;
                @include rem(17);
            }

            &:nth-of-type(-n+3){
                margin-bottom: 30px;
            }
        }
    }
}

.price_body{
    margin-top: 100px;
    @include media_query(){
        margin-top: 80px;
    }

    .price_body_title{
        @include rem(30);
        margin-bottom: 40px;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: solid 1px #2e639b;
        @include media_query(){
            @include rem(24);
            margin-bottom: 30px;
        }
    }

    .price_body_block{

        .price_block_title{
            padding: 10px 15px;
            background-color: #dae1e7;
            margin-bottom: 40px;
            @include media_query(){
                margin-bottom: 30px;
            }
            .price_semititle{
                @include rem(24);
                font-weight: bold;
                @include media_query(){
                    @include rem(20);
                }
            }
        }

        .price_body_imagearea{
            display: flex;
            margin-top: 40px;
            margin-left: -25px;
            @include media_query(){
                display: block;
                margin: 30px 0 0;
            }

            .price_body_image{
                max-width: 460px;
                margin-bottom: 40px;
                margin-left: 25px;
                width: calc(50% - 25px);
                @include media_query(){
                    max-width: 100%;
                    width: 100%;
                    margin: 0 0 30px 0;
                }

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .price_block_footer{
            width: auto;
            border: solid 1px #2e639b;
            background-color: #ffffff;
            padding: 40px 30px;
            margin-bottom: 100px;
            p{
                @include rem(20);
                @include media_query(){
                    @include rem(16);
                }
            }
        }
    }
}
