@use '../base/vars' as *;

.blog_list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -36px;
    @include media_query(){
        margin-left: 0;
    }
    .blog_article{
        width: calc(33.333% - 36px);
        margin-left: 36px;
        @include media_query(){
            width: 100%;
            margin-left: 0;
            &:not(:last-child){
                margin-bottom: 30px;
            }
        }
        &:nth-of-type(-n+3){
            margin-bottom: 60px;
            @include media_query(){
                margin-bottom: 30px;
            }
        }
        a{
            text-decoration: none;
            display: block;
            border-radius: 5px;
            //overflow: hidden;
            background-color: #fff;
        }
        .article_image{
            
            img{
                width: 100%;
                height: auto;
            }
        }
        .article_body{
            padding: 20px 25px 30px;
            time{
                @include rem(14);
                color: #2e639b;
            }
            span{
                display: block;
                margin: 20px 0 20px 0;
                @include rem(17);
                line-height: 1.88;
                color: #3e3e3e;
                
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .readmore{
                display: flex;
                justify-content: flex-end;
                @include rem(16);
                color: #2e639b;
                &::after{
                    font-family: "Font Awesome 5 Free";
                    content: "\f105";
                    margin-left: 10px;
                }
            }
        }
    }
}










#single{
    max-width: 995px;
    padding: 100px 25px 200px;
    margin: 0 auto;
    
    @include media_query(){
        padding: 50px 25px 100px;
    }
    
    @include rem(17);
    line-height: 2;
    
    @include media_query(){
        @include rem(16);
    }
    
    h1{
        @include rem(42);
        line-height: 1.29;
        margin: 1em 0;
        
        @include media_query(){
            @include rem(30);
        }
    }
    
    h2{
        @include rem(30);
        line-height: 1.33;
        border-bottom: solid 1px #2e639b;
        margin: 1em 0;
        
        @include media_query(){
            @include rem(22);
        }
    }
    
    h3{
        @include rem(24);
        line-height: 1.33;
        padding: 8px 15px;
        background-color: #dae1e7;
        margin: 1em 0;
        
        @include media_query(){
            @include rem(18);
        }
    }
    
    h4,h5,h6{
        @include rem(20);
        line-height: 1.29;
        margin: 1em 0;
        
        @include media_query(){
            @include rem(18);
        }
    }
    
    p{
        & + p{
            margin-top: 1em;
        }
    }
    
    ul{
        margin: 10px 0;
        
        li{
            display: flex;
            align-items: center;
            list-style: none;
            
            &::before{
                content: "";
                width: 15px;
                height: 2px;
                margin-right: 12px;
                background-color: #2e639b;
            }
        }
    }
    
    ol{
        margin: 10px 0;
        counter-reset: ol_li;
        
        li{
            list-style: none;
            
            &:before {
                margin-right: 12px;
                counter-increment: ol_li;
                content: counter(ol_li)'.';
                color: #2e639b;
            }
        }
    }
    
    a{
        
    }
    
    img{
        max-width: 100%;
        height: auto;
        margin: 10px 0;
    }
}