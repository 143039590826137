@use '../base/vars' as *;

// .treatment_img{
//     background-image: url(../img/general-parts/pagetop_01.jpg);
//     background-position: center center;
// }

.contents{

}

.general_treatment{
    position: relative;
    z-index: 99;
    max-width: 1180px;
    margin: 0 auto;
    padding: 140px 20px 0 20px;

    @include media_query(){
        padding: 60px 25px 0;
    }

    // &::before{
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     z-index: 0;
    //     content: "";
    //     display: block;
    //     width: 337px;
    //     height: 640px;
    //     box-shadow: 0 3px 6px 0 rgba(46, 99, 155, 0.3);
    //     background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
    //
    //     @include media_query(){
    //         width: 325px;
    //         height: 400px;
    //         top: 244px;
    //     }
    // }
}

.section_head{
    position: relative;
    display: flex;
    padding-right: 70px;
    z-index: 99;

    @include media_query(){
        padding-right: 0;
    }

    @include media_query(){
        display: block;
    }
}

.section_text{
    max-width: 560px;
    margin-right: 11.214%;

    @include media_query(){
        margin-right: 0;
    }

    .section_title{
        @include rem(42);
        line-height: 1.29;
        margin-bottom: 60px;

        span{
            display: block;
            @include rem(18);
            font-weight: normal;
        }

        @include media_query(){
            @include rem(32);
            margin-bottom: 560px;
            // font-weight: 500;
        }
    }

    .section_treatment_title{
        @include rem(42);
        line-height: 1.29;
        margin-bottom: 60px;

        span{
            display: block;
            @include rem(18);
            font-weight: normal;
        }

        @include media_query(){
            @include rem(24);
            margin-bottom: 560px;
            // font-weight: 500;
        }
    }

    p{
        @include rem(17);
        line-height: 2;

        @include media_query(){
            @include rem(16);
        }

        a{
            color: #2e639b;
        }
    }
}

.section_img{
    padding-top: 45px;
    margin-top: 25px;

    @include media_query(){
        position: absolute;
        top: calc(273px - 100px);
        left: -25px;
        right: auto;
        padding: 0;
        margin-right: 25px;
        margin-top: 0;
    }

    img{
        max-width: 100%;
        height: auto;

        // box-shadow: 100px 50px #2E639B;
        box-shadow: 100px 50px #5E8FC1;

        @include media_query(){
            // box-shadow: 50px 30px #2E639B;
            box-shadow: 50px 30px #5E8FC1;
        }
    }
}

.section_treatment_img{
    padding-top: 45px;
    margin-top: 25px;

    @include media_query(){
        position: absolute;
        top: calc(223px - 100px);
        left: -25px;
        right: auto;
        padding: 0;
        margin-right: 25px;
        margin-top: 0;
    }

    img{
        max-width: 100%;
        height: auto;

        // box-shadow: 100px 50px #2E639B;
        box-shadow: 100px 50px #5E8FC1;

        @include media_query(){
            // box-shadow: 50px 30px #2E639B;
            box-shadow: 50px 30px #5E8FC1;
        }
    }
}

.round_button{
    a{
        position: relative;
        display: flex;
        align-items: center;
        background-color: #fff;
        text-decoration: none;
        color: #2e639b;
        max-width: 160px;
        padding: 16px 25px;
        margin-top: 60px;
        border-radius: 30px;
        text-align: center;
        box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
        height: 54px;

        &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 25px;
            margin: auto;
            height: 16px;
            font-family: "Font Awesome 5 Free";
            content: "\f105";

        }
    }
}

.section_main{
    margin: 120px 0 250px 0;

    @include media_query(){
        margin: 100px 0;
    }
}

.section_item{
    display: flex;
    padding-right: 70px;

    @include media_query(){
        flex-direction: column;
        padding: 0;
    }

    & + .section_item{
        margin-top: 130px;

        @include media_query(){
            margin-top: 70px;
        }
    }

    &:nth-child(even){
        padding: 0 0 0 70px;

        @include media_query(){
            padding: 0;
        }

        .section_item_img{
            order: -1;
            margin: 50px -50px -50px 0;

            @include media_query(){
                margin: 0;
            }
        }
    }

    .section_item_text{
        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: #fff;
        padding: 60px;
        box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.2);
        border-radius: 10px;

        @include media_query(){
            border-radius: 0 0 10px 10px;
            padding: 40px 25px;
        }

        h3{
            @include rem(24);
            margin-bottom: 22px;
            font-weight: 500;

            @include media_query(){
                @include rem(18);
                margin-bottom: 20px;
                font-weight: 500;
            }
        }

        p{
            @include rem(17);
            line-height: 2;

            @include media_query(){
                @include rem(16);
            }
        }
    }

    .section_item_img{
        max-width: 460px;
        height: 438px;
        z-index: -1;
        margin: 50px 0 -50px -50px;

        @include media_query(){
            margin: 0;
            max-width: 100%;
            // width: 325px;
            // height: 244px;
            height: auto;
            overflow: hidden;
            border-radius: 10px 10px 0 0;
            order: -1;
        }

        img{
            width: 460px;
            height: 438px;
            object-fit: cover;
            vertical-align: bottom;

            @include media_query(){
                max-width: 100%;
                width: 100%;
                height: auto;
            }
        }
    }
}
