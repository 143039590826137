@use '../base/vars' as *;

html,
body{
    font-family: 'M PLUS 1p', sans-serif;
    min-height: 100%;
    background-color: #F2F7F9;
    color: $font-color;
}


.wrap{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.header{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    padding: 20px 50px;
    
    @include media_query(1210){
        width: 100%;
    }

    @include media_query(940){
        padding: 20px;
    }

    .header_inner{
        background-color: #fff;
        max-width: 1140px;
        margin: 0 auto;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 3px 6px 0 rgba(46, 99, 155, 0.3);

        display: flex;
        align-items: center;
    }

    .logo{
        a{
            display: block;
            padding: 20px 20px 20px 34px;
            text-decoration: none;
            color: #2E639B;

            @include media_query(940){
                padding: 0 20px;

                img{
                    width: 122px;
                    height: auto;
                }
            }
            
            @include media_query(){
                padding: 0 10px;
            }
        }
    }

    .global_nav{
        margin: 0 auto;

        @include media_query(940){
            display: none;
        }

        ul{
            display: flex;
            align-items: center;

            li{
                a{
                    display: block;
                    height: 85px;
                    line-height: 85px;
                    padding: 0 20px;
                    text-decoration: none;
                    color: #3E3E3E;
                    
                    @include media_query(1210){
                        @include rem(14);
                        padding: 0 10px;
                    }

                    &:hover{
                        color: #2E639B;
                    }
                }
            }
        }
    }

    .reservation_buttons{
        display: flex;
        align-items: center;
        margin-left: auto;

        .btn_tel{
            a{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 130px;
                height: 85px;
                text-align: center;
                text-decoration: none;
                color: #fff;
                background-color: #5E8FC1;
                
                @include media_query(1210){
                    @include rem(14);
                    width: 80px;
                }

                @include media_query(940){
                    width: 55px;
                    height: 55px;
                    padding: 0;
                    @include rem(12);
                }

                i{
                    margin-bottom: 8px;

                    @include media_query(940){
                        @include rem(20);
                    }
                }
            }
        }

        .btn_web{
            a{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 130px;
                height: 85px;
                text-align: center;
                text-decoration: none;
                color: #fff;
                background-color: #2E639B;

                @include media_query(940){
                    width: 55px;
                    height: 55px;
                    padding: 0;
                    @include rem(12);
                }
                
                @include media_query(1210){
                    @include rem(14);
                    width: 80px;
                }

                i{
                    margin-bottom: 8px;

                    @include media_query(940){
                        @include rem(20);
                    }
                }
            }
        }
    }

    .sp_menu{
        display: none;

        @include media_query(940){
            display: block;
            width: 55px;
            height: 55px;
            padding: 0;
            @include rem(30);
            line-height: 55px;
            text-align: center;
            color: #2e639b;
        }
    }
}





.sp_nav{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background-color: #f3f7f9;

    padding: 30px 25px;

    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-duration: .5s;

    .sp_nav_main{
        padding: 0 25px;
        margin: 5px 0 0;

        ul{
            li{
                a{
                    display: flex;
                    width: 100%;
                    padding: 15px 0;
                    text-decoration: none;
                    color: #3e3e3e;
                    @include rem(14);
                    border-bottom: solid 1px #2e639b;

                    &::after{
                        font-family: "Font Awesome 5 Free";
                        content: "\f105";
                        color: #2e639b;
                        @include rem(18);
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }

    .clinic_hours{
        border-radius: 10px;
        background-color: #fff;
        padding: 15px 13px;
        margin-top: 30px;

        h2{
            @include rem(14);
            font-weight: 500;
            color: #2e639b;
            padding-bottom: 12px;
            margin-bottom: 6px;
            border-bottom: solid 1px #2e639b;
        }

        table{
            width: 100%;

            td,th{
                text-align: left;
                font-weight: normal;
                padding: 6px 0;

                @include rem(12);
                color: #2e639b;
            }

            td{
                padding-left: 10px;
            }

            tr:last-child{
                td,th{
                    padding-bottom: 0;
                }
            }
        }
    }

    .sp_nav_buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -15px;
        margin-top: 30px;

        .btn_tel,
        .btn_web{
            width: calc(50% - 15px);
            max-width: 155px;
            margin-left: 15px;

            a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 54px;
                @include rem(16);
                text-decoration: none;
                color: #fff;
                box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
                border-radius: 30px;

                i{
                    margin-right: 10px;
                    @include rem(20);
                }
            }
        }

        .btn_tel a{
            background-color: #5e8fc1;
        }

        .btn_web a{
            background-color: #2e639b;
        }
    }

    .sp_nav_close{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #2e639b;

            @include rem(12);
            line-height: 1.5;

            i{
                @include rem(22);
            }
        }
    }
}

.nav_open{
    visibility: visible;
    opacity: 1;
}









.main{
    flex: 1 1 0;
}

.main_header_img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 460px;
    background-image: url(../img/general-parts/pagetop_03.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    text-align: center;

    @include media_query(){
        height: 500px;
        background-image: url(../img/general-parts/pagetop_03_sp.jpg);
    }

    h2{
        color: #fff;
        @include rem(40);
        letter-spacing: 6.3px;
        line-height: .8;
        text-shadow: 0 3px 6px rgba(46, 99, 155, 0.3);

        @include media_query(){
            @include rem(30);
            text-align: center;
            line-height: 1.19;
        }

        span{
            @include rem(16);
            font-weight: normal;
            letter-spacing: 1px;

            @include media_query(){
                @include rem(13);
            }
        }
    }
}

.about_header_img{
    background-image: url(../img/general-parts/pagetop_03.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_03_sp.jpg);
    }
}

.general_treatment_header_img{
    background-image: url(../img/general-parts/pagetop_01.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_01_sp.jpg);
    }
}

.self_treatment_header_img{
    background-image: url(../img/general-parts/pagetop_06.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_06_sp.jpg);
    }
}

.prevention_care_header_img{
    background-image: url(../img/general-parts/pagetop_07.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_07_sp.jpg);
    }
}

.price_header_img{
    background-image: url(../img/general-parts/pagetop_03.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_03_sp.jpg);
    }
}

.blog_header_img{
    background-image: url(../img/general-parts/pagetop_08.jpg);

    @include media_query(){
        background-image: url(../img/general-parts/pagetop_08_sp.jpg);
    }
}

.blue_header_img{
    background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);

    @include media_query(){
        align-items: flex-start;
        background-image: none;
        height: 340px !important;

        h2{
            padding-top: 140px;
        }
    }
}

.blue_header_main{
    @include media_query(){
        background-image: linear-gradient(to bottom, #2e639b 0%, #5e8fc1 800px, transparent 800px, transparent 100%);
    }
}

.breadcrumb{
    background-color: #F2F0F0;
    padding: 30px 50px;

    @include media_query(){
        padding: 30px 0px;
    }

    ul{
        max-width: 1190px;
        margin: 0 auto;
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li{
            list-style: none;
            line-height: 1.5;

            @include media_query(){
                @include rem(12);
            }

            &:not(:last-child){
                &::after{
                    display: inline-block;
                    margin: 0 15px;
                    font-family: "Font Awesome 5 Free";
                    content: "\f105";
                    color: #2E639B;
                }
            }

            a,
            span{
                color: #2E639B;
                text-decoration: none;
            }

            a{
                &:hover{
                    opacity: .7;
                }
            }

            span{

            }
        }
    }
}

.bunner{
    padding: 70px 50px;
    background-color: #D9E1E7;

    @include media_query(){
        padding: 60px 50px;
    }

    .bunner_inner{
        max-width: 1190px;
        margin: 0 auto;
        padding: 0 25px;

        @include media_query(){
            padding: 0;
        }
    }

    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -25px;

        @include media_query(){
            display: block;
            margin: 0;
        }

        li{
            list-style: none;
            width: calc(25% - 25px);
            margin-left: 25px;

            @include media_query(){
                width: 100%;
                margin: 0;

                &:not(:last-child){
                    margin-bottom: 35px;
                }
            }

            a{
                img{
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }
            }
        }
    }
}










.footer{
    position: relative;
    background-color: #5E8FC1;

    @include media_query(){
        //display: none;
    }

    .footer_inner{
        display: flex;
    }

    .access{
        @include rem(18);
        padding: 60px 50px;
        background-color: #2E639B;

        @include media_query(940){
            width: 100%;
            padding: 55px 25px;
        }

        h2{
            color: #fff;
            margin-bottom: 20px;
        }

        .address{
            line-height: 1.8;
            color: #fff;

            @include media_query(){
                @include rem(12);
            }
        }

        .tel{
            line-height: 1.8;
            color: #fff;
            
            a{
                color: #fff;
                text-decoration: none;
            }

            @include media_query(){
                @include rem(12);
            }
        }

        .btn_contact{
            margin-top: 40px;

            @include media_query(){
                margin-top: 30px;
            }

            a{
                display: block;
                width: 155px;
                height: 50px;
                color: #2E639B;
                background-color: #fff;
                border-radius: 30px;
                line-height: 50px;
                text-align: center;
                text-decoration: none;

                &::after{
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    content: "\f105";
                    margin-left: 10px;
                }

                @include media_query(){
                    @include rem(12);
                }
            }
        }

        .privacy{
            margin-top: 30px;

            @include media_query(){
                margin-top: 20px;
            }

            a{
                color: #fff;
                text-decoration: none;

                &::after{
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    content: "\f105";
                    margin-left: 10px;
                }

                @include media_query(){
                    @include rem(13);
                }
            }
        }
    }

    .footer_nav{
        flex: 1 1 0;
        max-width: 1000px;
        margin: 0 auto;
        padding: 130px 80px;

        @include media_query(940){
            display: none;
        }

        .footer_nav_inner{
            display: flex;
            flex-wrap: wrap;
            margin-left: -50px;
        }

        .section{
            width: calc(25% - 50px);
            margin-left: 50px;
            
            @include media_query(1210){
                width: calc(50% - 50px);
                margin-bottom: 20px;
            }
        }

        .section_ul{
            @include rem(18);
            
            @include media_query(1210){
                @include rem(16);
            }

            & + .section_ul{
                margin-top: 15px;
            }

            & > li{
                width: 100%;

                & > a{
                    display: flex;
                    width: 100%;
                    margin-bottom: 15px;
                    line-height: 1.5;
                    color: #fff;
                    text-decoration: none;

                    &::after{
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        content: "\f105";
                        margin-left: auto;
                    }
                }

                ul{
                    padding-left: 1em;

                    li{
                        &:not(:last-child){
                            margin-bottom: 15px;
                        }

                        a{
                            line-height: 1.5;
                            color: #fff;
                            text-decoration: none;

                            &::before{
                                display: inline-block;
                                content: "-";
                                margin: 0 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .copy{
        background-color: #F2F7F9;
        text-align: center;
        padding: 30px;
        @include rem(12);
        color: #4775A7;

        @include media_query(){
            padding: 20px 25px;
            @include rem(10);
        }
    }
}




.btn_scrolltop{
    position: absolute;
    right: 47px;
    top: -27px;
    z-index: 999;

    @include media_query(){
        right: 25px;
    }

    a{
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        background-color: #fff;
        border-radius: 50%;
        text-decoration: none;
        @include rem(20);
        
        i{
            transition-property: margin-top;
            transition-duration: .25s;
        }
        
        &:hover{
            opacity: 1;
            i{
                margin-top: -13px;
            }
        }
    }
}


/*--------------------------------
  ニシヤマ追加CSS
--------------------------------*/

/*--------------------------------
  下層ページ汎用見出し
--------------------------------*/
.heading_top-text{
    @include rem(42);
    font-weight: bold;
    line-height: 1.29;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
    letter-spacing: 0.15em;

    @include media_query(){
        @include rem(28);
        margin-bottom: 40px;

    }
}

.heading_second{
    @include rem(30);
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: solid 2px #2e639b;
    margin-bottom: 40px;
    @include media_query(){
        @include rem(24);
    }
}

.heading_third{
    @include rem(24);
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    //height: 50px;
    background-color: #dae1e7;

    @include media_query(){
        @include rem(18);
    }
}

// 下層ページ汎用見出しここまで

// リンクテキスト
.link-txt{
    color: #2e639b;
}

// 注釈テキスト
.note-txt{
    color: #e0497d;
}

// 箇条書きリストスタイル
.border-list{
    list-style:  none;
    margin:  0;
    padding: 0;

    .linelist{
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;

        @include media_query(){
            align-items: flex-start;
        }

        &::before{
            flex: 0 0 10px;
            content: "";
            width: 16px;
            height: 2px;
            background-color: #2e639b;
            margin-right: 12px;

            @include media_query(){
                width: 12px;
                margin-top: 1em;
            }

        }
    }
}

// 番号付きリストスタイル
.number-list{
    list-style:  none;
    margin:  0;
    padding: 0;

    .numlist{
        counter-increment: num;
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;

        &::before {
            content:  counter(num)'.';
            font-size: 17px;
            font-weight: 500;
            color: #2e639b;
            margin-right: 12px;
        }
    }
}

// 調整用マージン
.mb-SS{
    margin-bottom: 20px;
}

.mb-S{
    margin-bottom: 40px;
}

.mb-M{
    margin-bottom: 60px;
}

.mb-L{
    margin-bottom: 100px;
}

.ml-S{
    margin-left: 24px;

    @include media_query(){
        margin-left: 20px;
    }
}

.ml-25{
    margin-left: 0px;

    @include media_query(){
        margin-left: 25px;
    }
}

// 調整用フォントスタイル
.bold-18{
    font-weight: 500;
    font-size: 18px;

    @include media_query(){
        @include rem(14);
    }
}

// 左側 ＞が付く
.left-arrow{
    display: flex;

    &::before{
        display: inline-block;
        content: "\f105";
        margin-right: 12px;
        font-family: "Font Awesome 5 Free";
    }
}

/*--------------------------------
  ニシヤマ追加CSSここまで
--------------------------------*/

.sp_br{
  display: none;

  @include media_query(){
      display: block;
  }
}




.slider_wrap{
    width: 100%;
    padding: 80px 0;
    background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);

    @include media_query(){
        padding: 60px 0;
    }

    .swiper-container {
        padding-bottom: 50px;

        img{
            width: 100%;
            height: auto;
        }
    }

    .swiper-pagination-bullet{
        width: 16px;
        height: 16px;
        background-color: rgba(#fff, .5);

        @include media_query(){
            width: 12px;
            height: 12px;
            margin: 0 10px !important;
        }
    }

    .swiper-pagination-bullet-active{
        background-color: #fff;
    }
}





.btn_return{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    @include media_query(){
        margin-top: 50px;
    }

    a{
        display: block;
        width: 160px;
        height: 54px;
        color: #ffffff;
        box-shadow: 0 1px 15px 0 rgba(46, 99, 155, 0.3);
        background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
        border-radius: 30px;
        line-height: 54px;
        text-align: center;
        text-decoration: none;

        &::before{
            font-family: "Font Awesome 5 Free";
            content: "\f104";
            margin-right: 10px;
        }
        @include media_query(){
            width: 120px;
            height: 50px;
            line-height: 50px;
        }
    }
}


.ofs{
    max-width: 100%;
    overflow: scroll;
}
