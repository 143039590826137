@use '../base/vars' as *;

.treatment_list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -7.724%;
    @include media_query(){
        flex-direction: column;
        margin-left: 0;
    }

    .treatment_box{
        max-width: 267px;
        width: calc(33.333% - 7.724%);
        height: 206px;
        margin-left: 7.724%;
        box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.2);
        background-color: #fff;
        border-radius: 5px;
        a{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }
        @include media_query(){
            max-width: 260px;
            width: 100%;
            height: 200px;
            margin-left: 0;
            &:not(:last-child){
                margin-bottom: 40px;
            }
        }

        .box_head{
            .treatment_title{
                color: #3e3e3e;
                text-align: center;
                @include rem(17);
            }
        }

        .box_image img{
            width: 75px;
            height: 75px;
            object-fit: contain;
        }

        .box_body{
            display: flex;
            align-items: center;
            color: #2e639b;
            text-align: center;
            @include rem(17);
            &::after{
                font-family: "Font Awesome 5 Free";
                content: "\f105";
                margin-left: 10px;
            }
        }
    }
}

.edit-area{
    @include rem(17);
    line-height: 2;
    color: #3e3e3e;
    @include media_query(){
        @include rem(16);
    }
}

.treatment_pay{
    display: flex;
    align-items: center;
    margin-top: 60px;
    @include media_query(){
        margin-top: 40px;
    }
    .btn_treatment{
        a{
            display: block;
            width: 160px;
            height: 54px;
            color: #fff;
            box-shadow: 0 1px 15px 0 rgba(46, 99, 155, 0.3);
            background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
            border-radius: 30px;
            line-height: 54px;
            text-align: center;
            text-decoration: none;
            &::after{
                font-family: "Font Awesome 5 Free";
                content: "\f105";
                margin-left: 10px;
            }
        }
    }
}
