@use '../base/vars' as *;

.heading_top-text{
    span{
        display: block;
        margin-top: 2px;
        font-weight: normal;
        letter-spacing: 0px;
        @include rem(18);
        @include media_query(){
            @include rem(15);
        }
    }
}

.Infomation_list{
    li{
        list-style: none;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #2e639b;

        a{
            position: relative;
            width: 100%;
            padding: 30px 15px 30px 0;
            color: #3e3e3e;
            text-decoration: none;

            @include media_query(){
                padding: 15px 15px 15px 0;
            }

            &::after{
                display: flex;
                align-items: center;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 99;
                font-family: "Font Awesome 5 Free";
                content: "\f105";
                color: #2e639b;
                line-height: 1;
            }

            @include media_query(){
                padding-right: 10px;
            }


        }
        time{
            width: 100%;
            @include rem(14);

            @include media_query(){
                @include rem(13);
            }
        }
        span{
            display: block;
            width: 100%;
            @include rem(17);
            margin-top: 10px;
            line-height: 2.0;

            @include media_query(){
                @include rem(16);
                line-height: 1.5;
            }
        }
    }
}

.pagenation{
    display: flex;
    margin-top: 76px;
    justify-content: center;
    align-items: center;
    @include media_query(){
        margin-top: 38px;
    }
    .btn_prev{
        a{
            display: block;
            width: 160px;
            height: 54px;
            color: #2e639b;
            box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
            background-color: #ffffff;
            border-radius: 30px;
            line-height: 54px;
            text-align: center;
            text-decoration: none;
            &::before{
                font-family: "Font Awesome 5 Free";
                content: "\f104";
                margin-right: 10px;
            }
            @include media_query(){
                width: 120px;
                height: 50px;
            }
        }
    }
    .page{
        margin: 0 74px;
        @include media_query(){
            margin: 0 20px;
        }

    }
    .btn_next{
        a{
            display: block;
            width: 160px;
            height: 54px;
            color: #ffffff;
            box-shadow: 0 1px 15px 0 rgba(46, 99, 155, 0.3);
            background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
            border-radius: 30px;
            line-height: 54px;
            text-align: center;
            text-decoration: none;
            &::after{
                font-family: "Font Awesome 5 Free";
                content: "\f105";
                margin-left: 10px;
            }
            @include media_query(){
                width: 120px;
                height: 50px;
            }
        }
    }
}
