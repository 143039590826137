@use '../base/vars' as *;

.faqfaq-area{
    margin-top: 20px;

}
.question{
    display: flex;
    align-items: center;
    line-height: 1.33;
    @include rem(22);
    font-weight: 500;

    @include media_query(){
        align-items: flex-start;
    }

    span{
        flex: 1;
    }

    &::before{
        content: "Q";
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: #2e639b;
        color: #fff;
        font-weight: bold;
        display:  inline-block;
        margin-right: 18px;
        border-radius: 50%;
        text-align: center;

        @include media_query(){
            width: 45px;
            height: 45px;
            line-height: 45px;

        }
    }
}

.answer{
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 60px;
    @include rem(17);

    @include media_query(){
        @include rem(16);
    }

    span{
        flex: 1;
    }

    &::before{
        content: "A";
        width: 60px;
        height: 60px;
        border: 1px solid #2e639b;
        color: #2e639b;
        @include rem(30);
        font-weight: bold;
        display:  inline-block;
        margin-right: 18px;
        border-radius: 50%;
        text-align: center;

        @include media_query(){
            width: 45px;
            height: 45px;
            line-height: 45px;
            @include rem(24);

        }
    }
}
