@use '../base/vars' as *;

.contact-bg{
    max-width: 945px;
    padding: 60px 95px 80px 95px;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(46, 99, 155, 0.3);
    background-color: #fff;
    @include media_query(){
        padding: 40px 15px 60px 15px;
    }
}

.contact_top-text{
    display: block;
    @include rem(24);
    margin-bottom: 40px;
}

.Contact_list{
    li{
        list-style: none;

        &:not(:last-child){
            margin-bottom: 20px;
        }
    }
    label{
        @include rem(17);
        color: #2e639b;
        //display: flex;
        align-items: center;
    }
    span.required{
        @include rem(12);
        padding: 0px 8px;
        margin-left: 7px;
        color: #fff;
        max-width: 40px;
        height: 23px;
        background-color: #de5d6e;
    }
    input{
        font-family: 'M PLUS 1p', sans-serif !important;
        display: block;
        margin-top: 2px;
        padding-left: 15px;
        @include rem(16);
        width: 100%;
        max-width: 755px;
        height: 50px;
        line-height: 2.13;
        color: #333;
        border: solid 1px #2e639b;
        border-radius: 5px;
        background-color: #f3f7f9;
    }
    textarea{
        font-family: 'M PLUS 1p', sans-serif !important;
        display: block;
        margin-top: 2px;
        padding-left: 15px;
        padding-top: 10px;
        @include rem(16);
        width: 100%;
        max-width: 755px;
        height: 200px;
        line-height: 2.13;
        color: #333;
        border: solid 1px #2e639b;
        border-radius: 5px;
        background-color: #f3f7f9;
    }
    .error_text, .wpcf7-not-valid-tip{
        @include rem(14);
        color: #de5d6e;
        margin-bottom: 10px;
    }
    .error_border{
        border: solid 1px #d64a7a;
    }
}

.screen-reader-response{
    display: none !important;
    visibility: hidden;
}

.wpcf7-response-output{
    margin: 30px 0;
    text-align: center;
    @include rem(14);
    color: #de5d6e;
}

.privacy_check{
    margin-top: 60px;
    p{
        text-align: center;
        @include rem(17);

        &.tx14 {
            @include rem(14);
        }
    }
}


.transmit{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    @include media_query(){
        margin-top: 40px;
    }
    input[type=submit]{
        display: block;
        width: 160px;
        height: 54px;
        color: #ffffff;
        box-shadow: 0 1px 15px 0 rgba(46, 99, 155, 0.3);
        background-image: linear-gradient(to bottom, #2e639b, #5e8fc1);
        border-radius: 30px;
        line-height: 54px;
        text-align: center;
        text-decoration: none;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f105";
            margin-left: 10px;
        }

        &:hover{
            opacity: .7;
        }
    }
}
