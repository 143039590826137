@use '../base/vars' as *;

.single-container{
    max-width: 985px;
    margin: 0 auto;
    padding: 100px 20px 140px 20px;
    @include rem(17);

    @include media_query(){
        padding: 30px 20px 40px;
        @include rem(16);
    }
}

.blog-container{
    max-width: 1190px;
    margin: 0 auto;
    padding: 100px 25px 140px 25px;
    @include rem(17);

    @include media_query(){
        padding: 30px 25px 80px;
        @include rem(16);
    }
}

.up-single-container{
    max-width: 985px;
    margin: -100px auto 0;
    padding: 0 20px 100px 20px;

    @include media_query(){
        padding: 30px 20px 100px 20px;
        @include rem(16);
    }
}

.flex-wrapper{
    display: flex;
    flex-direction: row;

    @include media_query(){
        @include rem(14);
    }
}

.edit-area{
    margin-bottom: 60px;

    p{
        & + p{
            margin-top: 20px;
        }
    }

    img{
        // max-width: 100%;
        // height: auto;
        overflow: auto;
    }
}

.split-area{
    width: 472px;
}

p{
    line-height: 2;
}
